<template>
	<div class="box-table-dashboard">
		<template v-if="loaded">
			<template v-if="Object.keys(linePeriod).length || linePeriod.length">
				<div class="header">
					<div class="column-filter column-status">
						<div class="text"></div>
					</div>
					<div class="column-filter column-name">
						<div class="text">{{ $t("general.name") }}</div>
					</div>
					<div class="column-filter column-type-evaluation" v-if="$resize && $mq.above(1190)">
						<div class="text">{{ $t("responsibility.type_evaluation_label") }}</div>
					</div>
					<div class="column-filter column-date" v-if="$resize && $mq.above(1190)">
						<div class="text">{{ $t("general.date") }}</div>
					</div>
					<div class="column-simple-text column-project"></div>
					<div class="column-filter column-icon-priority"></div>
					<div class="column-filter column-user-avatar" v-if="filters.sources == ''"></div>
					<div class="column-filter column-action">
						<div class="text">{{ $t("general.actions") }}</div>
					</div>
				</div>
				<div class="content">
					<template v-for="(year, keyYear) in linePeriod">
						<template v-for="(month, keyMonth) in year">
							<template v-for="(week, keyWeek, index) in month">
								<div class="week">
									{{ $t("dashboard.week", { n: keyWeek }) }} <span class="month-start" v-if="index == 0">{{ `${keyMonth} ${keyYear}` }}</span>
								</div>
								<div v-for="toDo in week" class="table-row" :class="['accomplished', 'unaccomplished', 'free_day'].includes(toDo.status) || toDo.done ? 'evaluated' : ''">
									<!-- status sau bifa -->
									<div class="column-simple-text column-status">
										<template v-if="toDo.log_type == 'responsibility'">
											<div
												class="circle-status"
												:class="
													toDo.status == 'accomplished'
														? 'green'
														: toDo.status == 'unaccomplished'
														? 'red'
														: toDo.status == 'unevaluated'
														? 'blue'
														: toDo.status == 'free_day'
														? 'yellow'
														: 'grey'
												"
											></div>
										</template>
										<template v-else-if="['progress_reminder', 'performance_reminder'].includes(toDo.log_type)">
											<div class="reminder-icon">
												<icon-reminder />
											</div>
										</template>
										<template v-else-if="['meeting_log', 'meeting'].includes(toDo.log_type)">
											<div class="video-icon">
												<icon-video />
											</div>
										</template>
										<template v-else>
											<div class="checkbox-tbf-special" :class="{ active: toDo.done }" @click="toggleCheck(toDo, keyYear, keyMonth, keyWeek)">
												<icon-check />
											</div>
										</template>
									</div>

									<!-- name -->
									<div class="column-name-title column-name clickable" @click="openTaskItem(toDo)">
										<div class="name">{{ toDo.name }}</div>
									</div>

									<!-- tags sau roluri -->
									<div class="column-simple-text column-role-name" v-if="$resize && $mq.above(701)">
										<div class="text" v-if="toDo.log_type == 'responsibility'">{{ toDo.role }}</div>
										<div v-else-if="toDo.log_type == 'key_result'"></div>
										<template v-else-if="toDo.log_type == 'task'">
											<div class="selectedTags">
												<div
													class="tag"
													:class="{ is_light: tag.light }"
													v-for="(tag, index) in toDo.tags.slice(0, 3)"
													:style="`--color: ${tag.color_code}; --background-color: ${tag.color_code}30`"
												>
													<div class="text" :style="`--color: ${tag.color_code}`">{{ tag.name }}</div>
												</div>
												<div class="moreTags" v-if="toDo.tags.length > 3">
													<v-popover offset="5" trigger="hover" placement="bottom" popoverBaseClass="popover-tbf" :delay="{ show: 200, hide: 0 }">
														<div class="text">+ {{ toDo.tags.length - 3 }}</div>

														<template slot="popover">
															<div class="simple-text">
																{{
																	toDo.tags
																		.slice(3)
																		.map((el) => el.name)
																		.join(", ")
																}}
															</div>
														</template>
													</v-popover>
												</div>
											</div>
										</template>
										<div v-else-if="['meeting_log', 'meeting'].includes(toDo.log_type)">
											<div class="time">
												{{ toDo.start_time + " - " + toDo.end_time }}
											</div>
										</div>
										<div v-else></div>
									</div>
									<!-- tip evaluare sau repetitivitate -->
									<div class="column-name-desc column-type-evaluation" v-if="$resize && $mq.above(1190)">
										<template v-if="toDo.log_type == 'responsibility'">
											<div class="name">{{ toDo.type ? $t(`responsibility.type_evaluation.${toDo.type}`) : "" }}</div>
											<div class="description" v-if="toDo.type == 'value'">
												{{ $t("responsibility.progress_type_text." + toDo.progress_type, { to: parseFloat(toDo.target), unit: toDo.unity }) }}
											</div>
										</template>
										<template v-else-if="toDo.log_type == 'key_result'">
											<div class="name">
												{{ $t("dashboard.task_objectives") }}
											</div>
										</template>

										<template v-else-if="['meeting_log', 'meeting'].includes(toDo.log_type)">
											<template v-if="toDo.frequency == null">
												<div class="name">
													{{ $t("add_meeting.meeting") }}
												</div>
											</template>
											<template v-else>
												<div class="name">{{ $t("add_meeting.meeting") }}</div>
												<v-popover offset="5" trigger="hover" placement="bottom" popoverBaseClass="popover-tbf" :delay="{ show: 200, hide: 0 }" v-if="toDo.frequency">
													<div class="description">{{ $t("add_meeting.repetitive") }}</div>

													<template slot="popover">
														<div class="simple-text" v-if="toDo.frequency == 'daily'">{{ $t("dashboard.frequency.daily") }}</div>
														<template v-else>
															<div class="title">{{ toDo.frequency ? $t(`responsibility.frequencies.${toDo.frequency}`) : "" }}</div>

															<div class="description" v-if="toDo.frequency == 'weekly'">
																{{
																	toDo.measurement.length
																		? toDo.measurement
																				.map((a) => {
																					return $t(`calendar.long_days.${a}`);
																				})
																				.join(", ")
																		: ""
																}}
															</div>
															<div class="description" v-else-if="toDo.frequency == 'monthly'">{{ toDo.measurement.length ? toDo.measurement.join(", ") : "" }}</div>
															<div class="description" v-else-if="toDo.frequency == 'monthly_dynamically'">
																{{
																	toDo.measurement_dynamically
																		.map((a) => {
																			return $t(`calendar.weeks.${a}`);
																		})
																		.join(", ")
																		.replace(/, ([^,]*)$/, ` ${$t("general.and")} $1`)
																}}
																<br /><br />
																{{
																	toDo.measurement
																		.map((a) => {
																			return $t(`calendar.number_days.${a}`);
																		})
																		.join(", ")
																		.replace(/, ([^,]*)$/, ` ${$t("general.and")} $1`)
																}}
															</div>
															<div class="description capitalize" v-else-if="toDo.frequency == 'yearly'">
																{{
																	toDo.measurement.length
																		? toDo.measurement
																				.map((a) => {
																					return moment(a, "MM-DD").format("DD.MM");
																				})
																				.join(", ")
																		: ""
																}}
															</div>
														</template>
													</template>
												</v-popover>
											</template>
										</template>

										<template v-else-if="['progress_reminder', 'performance_reminder'].includes(toDo.log_type)">
											<div class="name">
												{{ $t("dashboard.reminder") }}
											</div>
										</template>

										<template v-else>
											<div class="name">{{ $t("dashboard.task") }}</div>
											<v-popover offset="5" trigger="hover" placement="bottom" popoverBaseClass="popover-tbf" :delay="{ show: 200, hide: 0 }" v-if="toDo.frequency">
												<div class="description">{{ $t("dashboard.repetitive") }}</div>

												<template slot="popover">
													<div class="simple-text" v-if="toDo.frequency == 'daily'">{{ $t("dashboard.frequency.daily") }}</div>
													<template v-else>
														<div class="title">{{ toDo.frequency ? $t(`responsibility.frequencies.${toDo.frequency}`) : "" }}</div>

														<div class="description" v-if="toDo.frequency == 'weekly'">
															{{
																toDo.measurement.length
																	? toDo.measurement
																			.map((a) => {
																				return $t(`calendar.long_days.${a}`);
																			})
																			.join(", ")
																	: ""
															}}
														</div>
														<div class="description" v-else-if="toDo.frequency == 'monthly'">{{ toDo.measurement.length ? toDo.measurement.join(", ") : "" }}</div>
														<div class="description" v-else-if="toDo.frequency == 'monthly_dynamically'">
															{{
																toDo.measurement_dynamically
																	.map((a) => {
																		return $t(`calendar.weeks.${a}`);
																	})
																	.join(", ")
																	.replace(/, ([^,]*)$/, ` ${$t("general.and")} $1`)
															}}
															<br /><br />
															{{
																toDo.measurement
																	.map((a) => {
																		return $t(`calendar.number_days.${a}`);
																	})
																	.join(", ")
																	.replace(/, ([^,]*)$/, ` ${$t("general.and")} $1`)
															}}
														</div>
														<div class="description capitalize" v-else-if="toDo.frequency == 'yearly'">
															{{
																toDo.measurement.length
																	? toDo.measurement
																			.map((a) => {
																				return moment(a, "MM-DD").format("DD.MM");
																			})
																			.join(", ")
																	: ""
															}}
														</div>
													</template>
												</template>
											</v-popover>
										</template>
									</div>
									<!-- date toDo -->
									<div class="column-simple-text column-date" v-if="$resize && $mq.above(1190)">
										<div class="text">{{ toDo.date | moment("DD MMM YYYY") }}</div>
									</div>
									<!-- Project -->
									<div class="column-simple-text column-project">
										<v-popover
											offset="5"
											trigger="hover"
											placement="bottom"
											popoverBaseClass="popover-tbf"
											:delay="{ show: 200, hide: 0 }"
											v-if="toDo.project"
											class="project-initials"
										>
											<icon-project class="icon" />
											<div class="initials">
												{{
													toDo.project.name
														.split(" ")
														.map((word) => word[0])
														.slice(0, 2)
														.join("")
												}}
											</div>

											<template slot="popover">
												<div class="simple-text" v-html="toDo.project.name"></div>
											</template>
										</v-popover>
									</div>
									<!-- Prioritate -->
									<div :class="`column-simple-text column-icon-priority ${toDo.priority ? toDo.priority : ''}`">
										<v-popover
											offset="5"
											trigger="hover"
											placement="bottom"
											popoverBaseClass="popover-tbf"
											:delay="{ show: 200, hide: 0 }"
											:disabled="!toDo.priority"
											v-if="!['responsibility', 'meeting_log', 'meeting'].includes(toDo.log_type)"
										>
											<icon-priority />
											<template slot="popover">
												<div class="simple-text">{{ $t("dashboard.priority." + toDo.priority) }}</div>
											</template>
										</v-popover>
									</div>
									<!-- Responsabil -->
									<div class="column-simple-text column-user-avatar" v-if="['task', 'task_log', 'performance_reminder'].includes(toDo.log_type) && filters.sources == ''">
										<v-popover
											:disabled="$resize && $mq.below(780)"
											offset="2"
											trigger="hover"
											placement="bottom"
											popoverBaseClass="popover-tbf btn-animated"
											:delay="{ show: 0, hide: 0 }"
											v-if="toDo.user && filters.user.id != toDo.user.id"
										>
											<img class="user-avatar" :src="toDo.user.avatar" />
											<template slot="popover">
												<div class="simple-text">{{ toDo.user.name }}</div>
											</template>
										</v-popover>
									</div>
									<div class="column-simple-text column-user-avatar" v-else-if="['meeting_log', 'meeting'].includes(toDo.log_type)">
										<v-popover
											:disabled="$resize && $mq.below(780)"
											offset="2"
											trigger="hover"
											placement="bottom"
											popoverBaseClass="popover-tbf btn-animated"
											:delay="{ show: 0, hide: 0 }"
										>
											<img class="user-avatar" :src="toDo.added_by.avatar" />
											<template slot="popover">
												<div class="simple-text">{{ toDo.added_by.name }}</div>
											</template>
										</v-popover>
									</div>
									<div class="column-simple-text column-user-avatar" v-else></div>
									<!-- Buton finalizare sau evaluare -->
									<div class="column-buttons column-action">
										<template v-if="toDo.log_type == 'responsibility'">
											<button
												class="btn-tbf center"
												:class="['unevaluated', 'future_unevaluated'].includes(toDo.status) ? 'blue' : 'grey'"
												@click="goToResponsability(toDo.id, toDo.date)"
											>
												<div class="text" v-if="$resize && $mq.above(450)">
													{{ ["unevaluated", "future_unevaluated"].includes(toDo.status) ? $t("general.evaluate") : $t("general.edit") }}
												</div>
												<div class="icon" v-else><icon-check class="check" /></div>
											</button>
										</template>
										<template v-else-if="['meeting_log', 'meeting'].includes(toDo.log_type)">
											<button class="btn-tbf center blue" @click="openTaskItem(toDo)">
												<div class="text" v-if="$resize && $mq.above(450)">{{ $t("general.view") }}</div>
												<div class="icon" v-else><icon-check class="check" /></div>
											</button>
										</template>
										<template v-else-if="toDo.log_type == 'performance_reminder'">
											<button class="btn-tbf center blue" @click="goToEvaluation(toDo.user.slug)">
												<div class="text" v-if="$resize && $mq.above(450)">{{ $t("general.evaluate") }}</div>
												<div class="icon" v-else><icon-check class="check" /></div>
											</button>
										</template>
										<template v-else-if="toDo.log_type == 'progress_reminder'">
											<a class="btn-tbf center blue" :href="`${objectives_fe_link}/obiectiv/${toDo.objective.slug}/${toDo.key_result.id}/actualizari`">
												<div class="text" v-if="$resize && $mq.above(450)">{{ $t("general.update") }}</div>
												<div class="icon" v-else><icon-check class="check" /></div>
											</a>
										</template>
										<template v-else>
											<button
												class="btn-tbf center"
												:id="`toDoBtn-${toDo.unique_id}`"
												:class="toDo.done ? 'completed' : 'blue'"
												@click="toggleCheck(toDo, keyYear, keyMonth, keyWeek)"
											>
												<div class="loader-spin" v-if="loadingSaveTask == toDo.unique_id"></div>
												<div class="text" v-else-if="$resize && $mq.above(450)">{{ toDo.done ? $t("general.done") : $t("general.finish") }}</div>
												<div class="icon" v-else><icon-check class="check" /></div>
											</button>
										</template>
									</div>
								</div>
							</template>
						</template>
					</template>
				</div>
			</template>
			<template v-else>
				<div class="empty-tasks">
					<div class="icon">
						<img src="/build/images/deleg_empty.svg" alt="Empty List" class="empty-bird" />
					</div>
					<div class="text">
						<div class="main">{{ $t("dashboard.no_responsibilities_today") }}</div>
					</div>
				</div>
			</template>
		</template>

		<loader-list-activities v-else />
	</div>
</template>

<script>
import LoaderListActivities from "../../PagesLoaders/ListActivities";

import IconCheck from "../../Icons/Check";
import IconPriority from "../../Icons/Flag";
import IconReminder from "../../Icons/Reminder";
import IconProject from "../../Icons/Projects";
import IconVideo from "../../Icons/Video";

export default {
	components: {
		LoaderListActivities,
		IconCheck,
		IconPriority,
		IconReminder,
		IconProject,
		IconVideo,
	},
	data() {
		return {
			loaded: false,
			activities: [],
			linePeriod: [],
			loadingSaveTask: false,
			objectives_fe_link: process.env.VUE_APP_OBJECTIVE_FE_LINK,
		};
	},
	props: ["filterJson"],
	watch: {
		filterJson: {
			deep: true,
			handler(newValue, oldValue) {
				this.filters = JSON.parse(newValue);

				if (oldValue && this.filters.loadedKey > JSON.parse(oldValue).loadedKey) {
					this.getListData();
				}
			},
		},
	},
	beforeDestroy() {
		this.$root.$off("refresh_ToDo_List");
	},
	async mounted() {
		this.filters = JSON.parse(this.filterJson);

		if (this.filters.loadedKey > 0) {
			this.getListData();
		}

		this.$root.$on("refresh_ToDo_List", () => {
			this.getListData();
		});
	},
	methods: {
		showModal(type, data = false, itemId = false) {
			this.$root.$emit("open_modal", type, data, itemId);
		},
		openTaskItem(task) {
			console.log(task);
			switch (task.log_type) {
				case "responsibility":
					// if(this.filters.user.right){
					this.$root.$emit("open_modal", "show_responsibility", {
						from: "user",
						userId: this.filters.user.id,
						userSlug: this.filters.user.slug,
						userRight: this.filters.user.right,
						id: task.id,
						unique_id: task.unique_id,
						date: moment(task.start).format("YYYY-MM-DD"),
					});
					// } else {
					//     this.$root.$emit('open_modal', 'view_responsibility', {
					//         from: 'user',
					//         userId: this.filters.user.id,
					//         userSlug: this.filters.user.slug,
					//         userRight: this.filters.user.right,
					//         id: task.id,
					//         unique_id: task.unique_id,
					//         date: moment(task.start).format('YYYY-MM-DD')
					//     })
					// }

					break;
				case "key_result":
					this.$root.$emit("open_modal", "show_task_key_result", { id: task.id });
					break;
				case "performance_reminder":
					this.$root.$emit("open_modal", "evaluate_user", {
						title: task.name,
						user: task.user,
						frequency: task.evaluation_frequency,
						log_type: task.log_type,
					});
					break;
				case "progress_reminder":
					this.$root.$emit("open_modal", "evaluate_user", {
						title: task.name,
						log_type: task.log_type,
						objective: task.objective,
						key_result: task.key_result,
					});
					break;
				case "meeting_log":
					this.$root.$emit("open_modal", "add_meeting", {
						meetingEditId: task.id,
						type: task.log_type,
						crud: "edit",
						date: task.date,
					});
					break;
				case "meeting":
					this.$root.$emit("open_modal", "add_meeting", {
						meetingEditId: task.id,
						type: task.log_type,
						crud: "edit",
						date: task.date,
					});
					break;
				default:
					this.$root.$emit("open_modal", "add_task", { crud: "edit", taskEditId: task.parent_id, date: task.date }, false);
					break;
			}
		},
		goToResponsability(id, date) {
			this.$root.$emit("open_modal", "show_responsibility", {
				from: "user",
				userId: this.filters.user.id,
				userSlug: this.filters.user.slug,
				userRight: this.filters.user.right,
				id: id,
				date: date,
			});
		},
		goToEvaluation(slug) {
			this.$router.push({ name: "user-show", params: { slug: slug }, query: { tab: "assessments" } });
		},
		getListData() {
			var paramsCall = this.generateParams();

			this.loaded = false;
			this.linePeriod = [];

			axios
				.get(`/${this.filters.user.slug}/logs-list`, {
					params: paramsCall,
				})
				.then(({ data }) => {
					this.linePeriod = data.data;
				})
				.finally(() => {
					this.loaded = true;
				});
		},
		toggleCheck(task, year, month, week) {
			var done = task.done ? 0 : 1;
			var newDate = task.date;
			var dateMoment = moment(task.date);
			var btnSubmit = document.getElementById(`toDoBtn-${task.unique_id}`);
			btnSubmit.disabled = true;

			this.loadingSaveTask = task.unique_id;

			var weekTask = this.linePeriod[year][month][week];

			if (task.log_type != "key_result") {
				axios
					.post(`/tasks/${task.parent_id}/check`, { date: newDate, done: done, from_calendar: 1 })
					.then(() => {
						Object.assign(
							weekTask.find((el) => el.unique_id == task.unique_id),
							{ done: done, status: done ? "accomplished" : "unevaluated" }
						);
					})
					.finally(() => {
						this.loadingSaveTask = false;
						btnSubmit.disabled = false;
					});
			} else {
				axios
					.patch(`${process.env.VUE_APP_OBJECTIVE_LINK}/api/tasks/${task.id}`, {
						name: task.name,
						done: done,
						deadline: newDate,
					})
					.then(() => {
						Object.assign(
							weekTask.find((el) => el.unique_id == task.unique_id),
							{ done: done, status: done ? "accomplished" : "unevaluated" }
						);
					})
					.finally(() => {
						this.loadingSaveTask = false;
						btnSubmit.disabled = false;
					});
			}
		},
		generateParams() {
			var paramsCall = {};

			if (this.filters.sources == "personal") {
				paramsCall.assignation = this.filters.sources;
			}
			paramsCall.log_types = this.filters.types;
			paramsCall.start_interval = moment(this.filters.dateRange.startDate).format("YYYY-MM-DD");
			paramsCall.end_interval = moment(this.filters.dateRange.endDate).format("YYYY-MM-DD");

			if (
				this.filters.filters_dashboard.objectives.length ||
				this.filters.filters_dashboard.key_results.length ||
				this.filters.filters_dashboard.tags.length ||
				this.filters.filters_dashboard.roles.length ||
				this.filters.filters_dashboard.projects.length
			) {
				paramsCall.objectives_ids = this.filters.filters_dashboard.objectives.length ? this.filters.filters_dashboard.objectives : [];
				paramsCall.key_results_ids = this.filters.filters_dashboard.key_results.length ? this.filters.filters_dashboard.key_results : [];
				paramsCall.tags_ids = this.filters.filters_dashboard.tags.length ? this.filters.filters_dashboard.tags : [];
				paramsCall.roles_ids = this.filters.filters_dashboard.roles.length ? this.filters.filters_dashboard.roles : [];
				paramsCall.projects_ids = this.filters.filters_dashboard.projects.length ? this.filters.filters_dashboard.projects : [];
			}

			return paramsCall;
		},
	},
};
</script>
